import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

import DefaultAxios from '../../_utils/DefaultAxios';

interface Props {
    name: string;
    value: string;
    onChange: Function;
    imagesUpload?: boolean;
    imagesUploadHandler?: Function;
}

const TextEditor = (props: Props) => {
    return (
        <Editor
            tinymceScriptSrc='https://d2jgpj59n9tcev.cloudfront.net/tinymce/tinymce.min.js'
            licenseKey='gpl'
            value={props.value}
            init={{
                height: 500,
                menubar: false,
                plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help code wordcount'
                ],
                toolbar:
                    `undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | ${props.imagesUpload ? 'image' : ''} removeformat code link | help`,
                invalid_elements:
                    'script',
                images_upload_handler: (blobInfo: any, success: any, failure: any, progress: any) => {
                    if (props.imagesUploadHandler) {
                        props.imagesUploadHandler(blobInfo, success, failure, progress);
                    } else {
                        const fd = new FormData();

                        fd.append('image', blobInfo.blob(), blobInfo.filename());

                        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/editor-upload-file`, fd)
                            .then(res => {
                                success(res.data);
                            })
                            .catch(err => {
                                failure('Image upload failed');
                            });
                    }
                }
            }}
            onEditorChange={(value) => props.onChange(props.name, value)}
        />
    );
}

export default TextEditor;